import BioAndSocials from "../components/BioAndSocials";
import BasicInfo from "../components/BasicInfo";
import headshot from "../images/headshot4.jpg";

function About() {
    return (
        <>
            <div className="column">
                <img src={headshot} className="headshot" alt="headshot" />
                <text className={"photoCredit"}>Photo courtesy of Maxi Tu Yip</text>
            </div>
            <div className="column">
                <BasicInfo />
                <br />
                <br />
                <BioAndSocials short={false} />
            </div>
        </>
    );
}

export default About;
