import "../styles/App.css";
import diagonalArrow from "../icons/diagonal-arrow.svg";

// eslint-disable-next-line no-useless-concat
var eDone = "amyl" + "in" + "@" + "ny" + "u." + "edu";
// eslint-disable-next-line no-useless-concat
var eProtocol = "mai" + "lto" + ":";

interface props {
    short: boolean;
}

const BioAndSocials = (props: props) => {
    const { short } = props;

    return (
        <div>
            {short ? (
                <text>
                    Amy Y.Q. Lin is a Chinese American writer. Her debut story can be found in <i>Catapult</i>. Her work was a semi-finalist for the 2022 <i>Sewanee Review</i> fiction contest and has
                    been supported by Tin House and the Sewanee Writers' Conference. An MFA candidate in fiction at NYU, she serves as the fiction editor for <i>Washington Square Review</i> and reads
                    for <i>One Story</i>. She lives in Seattle and New York.
                </text>
            ) : (
                <text>
                    <div className="paragraph">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed vehicula velit, quis fringilla nisl. Aliquam venenatis sem id nunc pellentesque, a pulvinar purus auctor. Sed
                        eu lorem id metus facilisis aliquam. Donec pulvinar est vel mauris condimentum, at vestibulum ipsum ultricies. Cras suscipit porta tortor, viverra ultrices massa gravida sit
                        amet. Praesent et magna sit amet enim pretium egestas ut nec sapien. Nam volutpat eros sed tortor ultricies congue. Praesent vel congue orci, nec sodales odio. Cras sed elit
                        commodo, dictum eros vel, tincidunt leo. Proin diam augue, scelerisque non sagittis id, sodales eget elit. Proin vitae massa sit amet enim convallis dapibus. Integer eget ante
                        eu mi pretium gravida sit amet id augue. Maecenas in tellus sit amet massa fermentum pretium. Fusce congue, eros sit amet lacinia convallis, massa lacus rutrum enim, at
                        accumsan lorem eros in ex.{" "}
                    </div>
                    <div className="paragraph">
                        In lobortis facilisis libero id dapibus. Vivamus semper nibh sed tellus interdum, vitae vehicula nulla volutpat. Proin nibh justo, dapibus a massa sed, sagittis dapibus massa.
                        Cras faucibus ornare tincidunt. Duis euismod semper porttitor. Nunc lacinia condimentum diam, convallis interdum neque vehicula ut. Ut sodales massa eget nulla lacinia, id
                        venenatis purus rutrum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque fermentum rhoncus congue. Mauris ut ligula
                        malesuada, placerat diam sed, scelerisque velit. Donec viverra ut tellus sed faucibus. Nulla eu mattis justo.{" "}
                    </div>
                    <div className="paragraph">
                        Curabitur faucibus faucibus elementum. Integer pretium sodales malesuada. Sed non placerat tellus, et fringilla mauris. Quisque hendrerit, dolor sed ultricies malesuada, augue
                        massa cursus turpis, non vehicula velit massa quis lacus. Maecenas sit amet libero sit amet orci imperdiet laoreet ut sit amet nisl. Class aptent taciti sociosqu ad litora
                        torquent per conubia nostra, per inceptos himenaeos. Nulla id pretium risus, vel maximus justo. Vivamus et finibus nibh, vitae faucibus purus. Etiam eu malesuada turpis. Etiam
                        ac mattis elit. Curabitur bibendum ut libero at consequat. Aenean et enim porta, tincidunt elit sit amet, convallis quam. Duis varius mi ex, et egestas elit lobortis vel. Nulla
                        blandit quam et condimentum tincidunt.
                    </div>
                </text>
            )}
            <br />
            <br />

            {/* Pubs */}
            <text>
                <p>Publications:</p>
                <ul>
                    <li>
                        "A Precious Stone" in&nbsp;
                        <a className="link" href="https://catapult.co/stories/amy-y-q-lin-fiction-short-story-a-precious-stone" target="_blank" rel="noopener noreferrer">
                            <i>Catapult</i>
                        </a>{" "}
                        (Dec 2022)
                    </li>
                    <li>
                        An interview with R.F. Kuang in&nbsp;
                        <a
                            className="link"
                            href="https://therumpus.net/2023/05/17/very-little-of-this-book-is-made-up-talking-with-r-f-kuang-about-her-novel/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i>The Rumpus</i>
                        </a>
                        &nbsp;(May 2023)
                    </li>
                    <li>
                        An interview with Josh Riedel in&nbsp;
                        <a className="link" href="https://one-story.com/2023/03/16/accessing-the-truth-an-interview-with-josh-riedel/" target="_blank" rel="noopener noreferrer">
                            <i>One Story</i>'s blog
                        </a>
                        &nbsp;(Mar 2023)
                    </li>
                    <li>
                        An interview with Jinwoo Chong in <i>Washington Square Review</i> (forthcoming)
                    </li>
                </ul>
            </text>
            <br />

            {/* Socials */}
            <div className="row">
                <div className="linkWithArrow">
                    <a className="socialLink" href="https://twitter.com/amyliny" target="_blank" rel="noopener noreferrer">
                        Twitter
                    </a>
                    <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
                </div>
                <div className="linkWithArrow">
                    <a className="socialLink" href="https://linkedin.com/in/amyliny" target="_blank" rel="noopener noreferrer">
                        LinkedIn
                    </a>
                    <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
                </div>
                <div className="linkWithArrow">
                    <a className="socialLink" href={eProtocol + eDone} target="_blank" rel="noopener noreferrer">
                        Email
                    </a>
                    <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
                </div>
            </div>
        </div>
    );
};

export default BioAndSocials;
