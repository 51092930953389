import React from "react";
import "./styles/App.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Route, Routes, BrowserRouter, Link } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <header className="header">
                    <div className="aboutMe">
                        <div className="menu">
                            <a className="logo" href="https://www.amyyqlin.com/" rel="noopener noreferrer">
                                AMY Y.Q. LIN
                            </a>
                            <br />
                            {/* <Link to="/about">
                                <button className="menuItem">About</button>
                            </Link>
                            <Link to="/services">
                                <button className="menuItem">Services</button>
                            </Link> */}
                        </div>

                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/about" element={<Services />} />
                        </Routes>
                    </div>
                </header>
            </BrowserRouter>
        </div>
    );
}

export default App;
