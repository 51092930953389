import "../styles/App.css";
import rightCaret from "../icons/right-caret.svg";

function BasicInfo() {
    return (
        <div>
            <text>
                Amy Y.Q. Lin
                <br />
                B. 1996, Washington D.C. Based in Seattle and New York.
            </text>
            <div className="row">
                <img src={rightCaret} className="caret" alt="caret" />
                <text>Last updated October 26 2023</text>
            </div>
        </div>
    );
}

export default BasicInfo;
