import pianoFlyer from "../images/piano-flyer.png";
import essayFlyer from "../images/essay-flyer.png";

function Services() {
    return (
        <>
            <div className="column">
                {/* <div className="photoRow"> */}
                <img src={essayFlyer} className="flyer" alt="flyer" />
            </div>
            <div className="column">
                <img src={pianoFlyer} className="flyer" alt="flyer" />
            </div>
        </>
    );
}

export default Services;
